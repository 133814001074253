@font-face {
  font-family: 'Basis Grotesque Regular';
  src: url('../public/fonts/basis-grotesque-regular/web/pro/basis-grotesque-regular-pro.woff2') format('woff2'),
       url('../public/fonts/basis-grotesque-regular/web/pro/basis-grotesque-regular-pro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basis Grotesque Light';
  src: url('../public/fonts/basis-grotesque-light/web/pro/basis-grotesque-light-pro.woff2') format('woff2'),
       url('../public/fonts/basis-grotesque-light/web/pro/basis-grotesque-light-pro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: 'Basis Grotesque Regular', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Basis Grotesque Light', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
